@tailwind base;
@tailwind components;
@tailwind utilities;

button.snipcart-base-button, [type='button'].snipcart-base-button, [type='reset'].snipcart-base-button, [type='submit'].snipcart-base-button {
  background-color: #1a4db3;
  color: #fff;
}
.snipcart-cart-summary--edit.snipcart-modal__container {
  @apply drop-shadow-2xl;
}

/* Custom Fonts */
@layer base {
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 100;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-Thin.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-Thin.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 100;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-ThinItalic.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-ThinItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 200;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-ExtraLight.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-ExtraLight.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 200;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-ExtraLightItalic.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-ExtraLightItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 300;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-Light.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-Light.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 300;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-LightItalic.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-LightItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-Regular.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-Regular.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 400;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-Italic.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-Italic.woff") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-Medium.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-Medium.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 500;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-MediumItalic.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-MediumItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 600;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-SemiBold.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-SemiBold.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 600;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-SemiBoldItalic.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-SemiBoldItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 700;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-Bold.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-Bold.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 700;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-BoldItalic.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-BoldItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 800;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-ExtraBold.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-ExtraBold.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 800;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-ExtraBoldItalic.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-ExtraBoldItalic.woff") format("woff");
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 900;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-Black.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-Black.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 900;
    font-display: swap;
    src: url("../public/fonts/Inter/Inter-BlackItalic.woff2") format("woff2"),
         url("../public/fonts/Inter/Inter-BlackItalic.woff") format("woff");
  }
}

/* Hero */

.heroTitle h1,
.heroTitle h2,
.heroTitle h3 {
  @apply w-auto;
  @apply mx-auto;
  @apply text-4xl;
  @apply font-extrabold;
  @apply leading-none;
  @apply tracking-tight;
  @apply sm:text-5xl;
  @apply md:text-6xl;
  @apply lg:text-7xl;
  @apply sm:max-w-3xl;
  @apply text-brand-1-500;
}
.heroTitle h1 span,
.heroTitle h2 span,
.heroTitle h3 span {
  @apply text-brand-2-500;
}

.heroTitleDark h1,
.heroTitleDark h2,
.heroTitleDark h3 {
  @apply w-auto;
  @apply mx-auto;
  @apply text-4xl;
  @apply font-extrabold;
  @apply leading-none;
  @apply tracking-tight;
  @apply sm:text-5xl;
  @apply md:text-6xl;
  @apply lg:text-7xl;
  @apply sm:max-w-3xl;
  @apply text-white;
}
.heroTitleDark h1 span,
.heroTitleDark h2 span,
.heroTitleDark h3 span {
  @apply text-brand-1-400;
}

/* Animations */
.animate-none {
  animation: none;
}
.animate-spin {
  animation: spin 1s linear infinite;
}
.loader {
  @apply border-t-brand-1-500;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
.animate-bounce	{
  animation: bounce 1s infinite;
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

/* Custom Classes */
.inner-wrapper {
  @apply mx-3;
  /* @apply px-6; */
  @apply lg:px-2;
  @apply xl:px-5;
  @apply w-auto;
  @apply xl:mx-auto;
  @apply xl:w-full;
  @apply max-w-8xl;
}

a.active {
  @apply text-purple-500;
}


/* Custom Link Stlyes */
.highlighter {
  text-decoration: none;
  border-bottom: 1px solid rgba(250, 208, 0, 0.4);
  box-shadow: inset 0 -5px 0 rgba(250, 208, 0, 0.4);
  transition: 400ms all ease-out;
  padding: 0 0.3rem;
}
.highlighter:hover {
  box-shadow: inset 0 -20px 0 #fad000;
  border-bottom: 3px solid #fad000;
  transition: 150ms all ease-out;
}

.quickline {
  position: relative;
  transition: all .15s ease-in-out;
}
.quickline:after {
  content: "";
  @apply bg-brand-1-50;
  height: 3px;
  left: 0;
  width: 100%;
  position: absolute;
  bottom: -5px;
}
.quickline:before {
  content: "";
  @apply bg-brand-1-500;
  height: 3px;
  left: 0;
  width: 25%;
  position: absolute;
  bottom: -5px;
  transform-origin: 0 0;
  transition: all 300ms ease-in-out;
  z-index: 1;
}
.quickline:hover:before {
  transform: scaleX(1);
  @apply bg-brand-1-500;
  width: 100%;
  transition: all 150ms ease-in-out;
}

/* Hack to get the "react-datetime" input styled */
.form-control {
  @apply block;
  @apply w-full;
  @apply mt-1;
  @apply border;
  @apply border-gray-300;
  @apply rounded-md;
  @apply shadow-sm;
  @apply focus:ring-indigo-500;
  @apply focus:border-indigo-500;
  @apply sm:text-sm;
}

/* Snipcart */

.snipcart-cart-summary--edit.snipcart-modal__container {
  box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 10%);
}

/*  Add to my calendar button */
.chq-atc {
  @apply relative inline-block mb-6;
}

.chq-atc--button {
  @apply inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}

.chq-atc--button svg {
    @apply mr-2;
}

.chq-atc--dropdown {
  @apply px-4 py-3 border border-gray-300 text-xs font-medium rounded text-gray-700 bg-white shadow-lg flex flex-col space-y-4 w-full z-10 whitespace-nowrap text-left absolute;
}

.chq-atc--dropdown a {
  @apply hover:underline;
}

.full-width {
  @apply w-screen;
  @apply relative;
  @apply left-1/2;
  @apply right-1/2;
  @apply ml-[-50vw];
  @apply mr-[-50vw];
}
